import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";

//components
import Accordion from "../components/Accordion";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/inversion_progresiva_ica.jpeg)`}}>
                        <h1>Inversión Progresiva ICA</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Esta temporada, Skyler Finance te brinda la
                            tranquilidad que necesitas para cumplir tus metas.</h1>
                        <p className="services_section_1__paragraph">
                            Skyler Finance te ayuda a hacer crecer tu dinero. Prepárate para la posibilidad de generar
                            atractivos rendimientos con nuestro instrumento de inversión:
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>Inversión Progresiva ICA</h1>
                            <div>
                                <ul style={{color: '#000000'}}>
                                    <li>Tasa fija de 1% de rendimiento mensual en la última renovación.</li>
                                    <li>Apertura desde $250,000 pesos.</li>
                                    <li>Opciones de renovación automática.</li>
                                    <li>Contrato a 6 meses.</li>
                                    <li>Líquidez Mensual sobre los Interéses.</li>
                                    <li>Flexibilidad para realizar aportaciones e incrementar tu ahorro.</li>
                                    <li>Atractivos Rendimientos.</li>
                                </ul>
                            </div>
                            <div>
                                <h1 style={{color: '#000000', fontSize: 20}}>Ejemplo de inversión con Interés
                                    Compuesto.</h1>
                                <p style={{color: '#000000'}}>El siguiente ejemplo se basa en una inversión inicial de
                                    250 mil pesos y cuyos intereses no se retiran, se reinvierten al cabo de los 6 meses
                                    del contrato.</p>
                                <div className="table-wrapper">
                                    <table style={{color: '#000000'}}>
                                        <thead>
                                        <tr>
                                            <th>Mes</th>
                                            <th>Capital</th>
                                            <th>Interés 1%</th>
                                            <th>Nuevo capital</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>250,000.00</td>
                                            <td>2,500.00</td>
                                            <td>252,500.00</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>252,500.00</td>
                                            <td>2,525.00</td>
                                            <td>255,025.00</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td> 255,025.00</td>
                                            <td>2,550.25</td>
                                            <td>257,575.25</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>257,575.25</td>
                                            <td>2,575.75</td>
                                            <td>260,151.00</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>260,151.00</td>
                                            <td>2,601.51</td>
                                            <td>262,752.51</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>262,752.51</td>
                                            <td>2,627.53</td>
                                            <td>265,380.04</td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan="2"></td>
                                            <td>Interéses pagados</td>
                                            <td>15,380.04</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2"></td>
                                            <td>Utilidad / inversion incial</td>
                                            <td>6.15%</td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <h1 style={{color: '#000000', fontSize: 20}}>Generamos tus utilidades invirtiendo en
                                    arbitraje de criptomonedas</h1>

                                <h1 style={{color: '#000000', fontSize: 25}}>Preguntas frecuentes</h1>
                                <Accordion/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
